.header_style {
  position: fixed;
}

.navbar-element {
  cursor: pointer;
  margin: auto;
}

.navbar-static-placeholder {
  position: static;
  height: 91px;
}

@media screen and (max-width: 992px) {
  .navbar-static-placeholder {
    height: 72px;
  }
}

.login-dropdown-color .dropdown-item {
  color: #333 !important;
  font-weight: 300;
  line-height: 2rem !important;
}

.login-dropdown-color .dropdown-item.disabled {
  color: #3337 !important;
  /* background-color: #f00; */
}

.login-dropdown-color .dropdown-item:hover {
  background-color: #182539bb;
  color: white !important;
}

.login-dropdown-color .dropdown-item:active {
  background-color: #182539;
}

.navbar-avatar {
  width: 1.5rem;
  height: auto;
  border-radius: 100%;
}

/* New Coupon CSS */

.create-coupon-form-1 {
  margin: 6rem 0;
}

.create-coupon-form-1 .red-border .form-control,
.create-coupon-form-1 .red-border .input-group-append button {
  border: 1px solid #f00;
}

.create-coupon-form-1 .input-group input {
  outline: none;
}

.create-coupon-form-1 .large {
  font-size: 2rem;
}

.create-coupon-form-1 .create-coupon-button {
  margin-top: 1rem;
  font-size: 1.5rem;
}

/* Redeem Coupon CSS */

#redeem-file-drag {
  border: 3px solid #182539;
  border-radius: 1rem;
  border-style: dashed;
  margin: 1rem auto;
  padding: 30vh 1rem;
  text-align: center;
  color: #182539;
  font-weight: 500;
  font-size: 2rem;
  cursor: pointer;
}

#redeem-file-drag .alert {
  margin-top: 1rem;
}

/* Coupon Card */

.coupon-card {
  border: 5px solid #182539;
  margin: 1rem auto;
  padding: 2rem;
  font-weight: 800;
  color: #182539;
}

.coupon-card .coupon-field {
  display: block;
  word-break: break-all;
}

.coupon-card .coupon-field .label {
  text-decoration: underline;
}

/* Transaction Modal */

.modal-content {
  color: #000;
}

/*===================

=====================*/
.banner {
  background-image: url("/img/home-slider/slider-8.jpg");
  background-color: #fdf3f4;
  display: block;
  min-height: 640px;
  background-size: cover;
  overflow: hidden;
  padding-top: 50px;
}
.banner p {
  margin-top: 20%;
  color: #fff;
  visibility: inherit;
  transition: none 0s ease 0s;
  text-align: left;
  line-height: 65px;
  border-width: 0px;
  padding: 0px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 16px;
  min-height: 0px;
  min-width: 100%;
  max-height: none;
  max-width: 100%;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
}
.banner p span.line {
  width: 80px;
  height: 1px;
  background: rgb(255, 255, 255);
  display: inline-block;
  margin: 0px 0px 0px 10px;
  transition: none 0s ease 0s;
  text-align: left;
  line-height: 65px;
  border-width: 0px;
  padding: 0px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 16px;
}
.banner h2 {
  font-family: Poppins, sans-serif;
  font-weight: 600 !important;
  color: #fff !important;
  visibility: inherit;
  transition: none 0s ease 0s;
  text-align: left;
  line-height: 68px;
  border-width: 0px;
  margin: 0px;
  padding: 0px;
  letter-spacing: 0px;
  font-size: 40px;
  white-space: normal;
  min-height: 0px;
  min-width: 670px;
  max-height: none;
  max-width: 670px;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
}
.banner h2 span {
  font-family: "Playfair Display", serif;
  font-style: italic;
  color: #fff;
  text-shadow: none;
  font-weight: bold !important;
  text-decoration: underline;
}
.banner h6 {
  font-size: 18px;
  color: #fff;
  margin-top: 15px;
}

@media (max-width: 1200px) {
  .banner h1 {
    margin-top: 10%;
  }
}
@media (max-width: 1024px) {
  .banner {
    min-height: 440px;
  }
}
@media (max-width: 991px) {
  .com-sec-hed {
    padding-top: 30px !important;
  }
}

.bg-light.scrolled {
  background-color: #172460 !important;
  transition: 500ms ease;
}
.survey-nav .fixed-top {
  background: #000 !important ;
}
.com-sec-hed {
  background-image: url(/img/home-slider/slider-8.jpg);
  background-color: #fdf3f4;
  display: block;
  background-size: cover;
  overflow: hidden;
  padding-top: 50px;
}
.com-top-space {
  margin-top: 42px;
}
